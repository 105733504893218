import { init } from "@rematch/core";
import loadingPlugin from "@rematch/loading";
import { models } from "../models";
import { thunk } from "redux-thunk";
import { getStorageData } from "lib/utils";

// Get user info from storage or set to null if not found
const userInfoFromStorage = getStorageData("userInfo") || null;
// Get successLogin from storage or set to null if not found
const userLoginSuccess = getStorageData("successLogin") || null;
// Get successOTP from storage or set to null if not found
const userOTPSuccess = getStorageData("successOTP") || null;

// Initial state of the store
const initialState = {
  AuthLogin: { successLogin: userLoginSuccess }, // Initial state for AuthLogin model
  AuthOTP: {
    userInfo: userInfoFromStorage, // Initial state for AuthOTP model's userInfo
    successOTP: userOTPSuccess, // Initial state for AuthOTP model's successOTP
  },
};

// Initialize the store using Rematch
const store = init({
  global: true, // Make the store globally accessible
  models, // Pass the models defined in the models file
  plugins: [loadingPlugin({ type: "full" })], // Use the loading plugin for loading states
  redux: {
    initialState: initialState, // Set the initial state of the Redux store
    middlewares: [thunk], // Use Redux thunk middleware for handling asynchronous actions
  },
});

export default store;
