import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { FormInput, FormSelect } from "@/components/shared/Input";
import { useForm } from "react-hook-form";
import { LoginSchema } from "@/lib/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "components/shared/Message";
import { LoginIcon } from "assets/icons";
import { Loader2 } from "lucide-react";
import logo from "@/assets/img/logo.png";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const AuthLogin = useSelector((state) => state.AuthLogin);
  const { loading, error, successLogin } = AuthLogin;

  useEffect(() => {
    if (successLogin) {
      navigate("/OTPVerification");
    }
  }, [navigate, successLogin]);

  const form = useForm({
    resolver: LoginSchema,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values) => {
    const formData = {
      email: values.email,
      password: values.password,
    };

    // console.log(formData);
    await dispatch.AuthLogin.login(formData);
  };

  return (
    <>
      <div className="w-full lg:grid lg:min-h-screen lg:grid-cols-2 xl:min-h-screen  min-h-screen">
        <div className="w-full  md:min-h-screen flex items-center justify-center lg:bg-transparent bg-white min-h-screen">
          <div
            className="lg:max-w-md md:max-w-md w-full p-6 rounded-md shadow-lg lg:rounded-none lg:shadow-none"
            style={{ width: "97%" }}
          >
            <div className="grid gap-2 text-center">
              <CardHeader className="space-y-0">
                <div className="flex items-center justify-center mb-4">
                  <img
                    className="img"
                    height="200px"
                    width="200px"
                    src={logo}
                    alt="liberty_logo"
                  />
                </div>
                <CardTitle className="text-2xl text-center">
                  <p className="text-center text-lg font-medium capitalize">
                    {error ? (
                      <Message>Error: {error}</Message>
                    ) : (
                      <>Sign in to your account</>
                    )}
                  </p>
                  {/* <span className="flex items-center justify-center text-sm">
                    {loading && <Loading />}
                  </span> */}
                </CardTitle>
              </CardHeader>
            </div>

            {/* //form */}
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="">
                <CardContent className="space-y-3">
                  <FormInput
                    name="email"
                    label="Email"
                    type="email"
                    required={true}
                    control={form.control}
                  />

                  <FormInput
                    name="password"
                    label="Password"
                    type="password"
                    maxLength={40}
                    required={true}
                    control={form.control}
                  />
                </CardContent>
                <CardFooter className="flex flex-col pl-4 pr-4 pb-2">
                  <div className="w-full flex items-center justify-between px-3 mb-3 "></div>

                  <Button
                    disabled={loading}
                    className=" w-full text-sm h-8 lg:flex justify-center align-middle items-center"
                    variant="default"
                    size="sm"
                    type="submit"
                  >
                    {loading ? (
                      <span className=" flex items-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Please wait
                      </span>
                    ) : (
                      `Sign In`
                    )}
                  </Button>
                </CardFooter>
              </form>
            </Form>
          </div>
        </div>

        <div className="hidden h-full bg-gray-100 lg:flex items-center justify-center flex-1 text-black">
          <div className="max-w-md text-center pt-48 pb-0  min-h-screen">
            <LoginIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
