import React from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import * as Dropdown from "@/components/ui/dropdown-menu";
import * as Pv from "@/components/ui/popover";
import * as Cmd from "@/components/ui/command";
import { Button } from "components/ui/button";
import { useStateContext } from "context/ContextProvider";
import { FaBarsStaggered } from "react-icons/fa6";
import { BreadCrumbs } from "@/components/shared/BreadCrumbs";
import {
  ArrowRight,
  MoonIcon,
  SunIcon,
  LifeBuoy,
  LogOut,
  PlusCircle,
  User,
} from "lucide-react";

const Header = () => {
  const { activeMenu, toggleSidebar, currentColor, setTheme } =
    useStateContext();

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch.AuthLogout.logout();
  };

  return (
    <header className={`relative w-full  py-6 md:static`}>
      <div className={`flex h-full items-center justify-between px-0`}>
        <Button
          style={{ color: currentColor }}
          className={`menuBtn mx-2 -ml-1 mr-5 flex p-0 h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-150 ease-in hover:scale-105  focus:outline-none ${
            !activeMenu && "rotate-180"
          } `}
          onClick={toggleSidebar}
          aria-label="Menu"
          variant="ghost"
          size={"icon"}
        >
          <FaBarsStaggered className={`text-black text-lg dark:text-white`} />
        </Button>
        <div className="flex flex-1 justify-start lg:mr-32">
          <BreadCrumbs />
        </div>
        <motion.ul className="flex flex-shrink-0 items-center ">
          <motion.li className="relative space-x-4">
            <Pv.Popover>
              <Pv.PopoverTrigger asChild>
                <Button
                  type="button"
                  title="Profile"
                  variant="outline"
                  size="icon"
                  className="py-4 px-2 ml-2 overflow-hidden rounded-full "
                >
                  <User
                    size={18}
                    className="overflow-hidden rounded-full duration-500 ease-in-out hover:scale-110"
                  />
                </Button>
              </Pv.PopoverTrigger>
              <Pv.PopoverContent align="end" className={`p-0 w-[180px]`}>
                <Cmd.Command>
                  <Cmd.CommandList>
                    <Cmd.CommandGroup>
                      <Dropdown.DropdownMenu>
                        <Dropdown.DropdownMenuTrigger
                          className={`py-0 px-2 mt-1 mb-1`}
                          asChild
                        >
                          <Cmd.CommandItem>
                            <LifeBuoy className="mr-3 size-4" />
                            <Button title="Profile" variant="ghost" size="icon">
                              Theme
                            </Button>
                            <Cmd.CommandShortcut>
                              <ArrowRight className="size-4" />
                            </Cmd.CommandShortcut>
                          </Cmd.CommandItem>
                        </Dropdown.DropdownMenuTrigger>

                        <Dropdown.DropdownMenuContent
                          className="w-[150px]"
                          align="end"
                        >
                          <Dropdown.DropdownMenuItem
                            onClick={() => setTheme("light")}
                          >
                            <SunIcon className="mr-3 size-4" />
                            <span>Light</span>
                          </Dropdown.DropdownMenuItem>

                          <Dropdown.DropdownMenuItem
                            onClick={() => setTheme("dark")}
                          >
                            <MoonIcon className="mr-3 size-4" />
                            <span>Dark</span>
                          </Dropdown.DropdownMenuItem>

                          <Dropdown.DropdownMenuItem
                            onClick={() => setTheme("system")}
                          >
                            <PlusCircle className="mr-3 size-4" />
                            <span>System</span>
                          </Dropdown.DropdownMenuItem>
                        </Dropdown.DropdownMenuContent>
                      </Dropdown.DropdownMenu>

                      <Cmd.CommandItem onSelect={logoutHandler}>
                        <LogOut className="mr-2 size-4" />
                        <span>Log out</span>
                        {/* <Cmd.CommandShortcut>⌘P</Cmd.CommandShortcut> */}
                      </Cmd.CommandItem>
                    </Cmd.CommandGroup>
                  </Cmd.CommandList>
                </Cmd.Command>
              </Pv.PopoverContent>
            </Pv.Popover>
          </motion.li>
        </motion.ul>
      </div>
    </header>
  );
};

export default Header;
