import api from "@/lib/api";
import { setStorageData } from "@/lib/utils";

export const AuthLogin = {
  state: {
    loading: false,
    loginInfo: {},
    success: null,
    error: null,
    successLogin: false,
  },
  reducers: {
    USER_LOGIN_REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    USER_LOGIN_SUCCESS(state, payload) {
      return {
        loading: false,
        loginInfo: payload?.data[0],
        success: payload?.status.message.includes("Successfully")
          ? payload?.status.message
          : null,
        successLogin: payload?.status.message.includes("Successfully")
          ? true
          : false,
      };
    },
    USER_LOGIN_FAIL: (state, payload) => {
      return {
        loading: false,
        loginInfo: {},
        success: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    USER_LOGOUT: (state, payload) => {
      return {};
    },
  },
  effects: (dispatch) => {
    return {
      async login(formData) {
        try {
          this.USER_LOGIN_REQUEST();
          const { data } = await api.post(`/auth/login`, formData);
          let dataItem = data?.data[0];

          let successLogin = data?.status.message.includes("Successfully")
            ? true
            : false;

          if (successLogin) {
            setStorageData("name", dataItem?.name);
            setStorageData("userId", dataItem?.userId);
            setStorageData("successLogin", successLogin);
            this.USER_LOGIN_SUCCESS(data);
          }
        } catch (error) {
          this.USER_LOGIN_FAIL(error);
        }
      },
    };
  },
};

//otp
export const AuthOTP = {
  state: {},
  reducers: {
    OTP_REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    OTP_SUCCESS: (state, payload) => {
      return {
        loading: false,
        userInfo: payload?.data[0],
        successOTP: payload?.status.message.includes("Successfully")
          ? true
          : false,
      };
    },
    OTP_FAIL: (state, payload) => {
      return {
        loading: false,
        error: payload ? payload.message : payload,
        // error: payload ? payload.response?.statusText : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async VerifyOTP(formData) {
        try {
          this.OTP_REQUEST();
          const { data } = await api.put(`/auth/validateOTP`, formData);
          let dataItem = data?.data[0];

          let successOTP = data?.status.message.includes("Successfully")
            ? true
            : false;

          if (successOTP) {
            setStorageData("userInfo", dataItem);
            setStorageData("userId", dataItem?.userId);
            setStorageData("successOTP", successOTP);
            setStorageData("name", dataItem?.name);
            setStorageData("token", dataItem.token);
            this.OTP_SUCCESS(data);
          } else {
            dispatch.AuthLogout.logout();
          }
        } catch (error) {
          this.OTP_FAIL(error);
        }
      },
    };
  },
};

//register
export const registerUser = {
  state: {
    user: {},
    loading: false,
    success: null,
    error: null,
    registerdSuccess: "",
    successRegister: false,
  },

  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        user: payload,
        registerdSuccess: payload ? true : false,
        success: payload?.status.message.includes("Successfully")
          ? payload?.status.message
          : null,
        successRegister: payload?.status.message.includes("Successfully")
          ? true
          : false,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        user: {},
        success: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        user: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Add(formData) {
        try {
          this.REQUEST();

          const { data } = await api.post(`/auth/register`, formData);

          // console.log("reg Data", data);
          // let registerdSuccess = data ? true : false;

          let successRegistration = data?.status.message.includes(
            "Successfully"
          )
            ? true
            : false;

          if (successRegistration) {
            this.SUCCESS(data);
          } else {
            dispatch.AuthLogout.logout();
          }

          //this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

export const AuthResetPassword = {
  state: {
    resetPasswordInfo: [],
    loading: false,
    success: null,
    error: null,
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        resetPasswordInfo: payload?.data,
        success: payload?.status.message.includes("Successfully")
          ? payload?.status.message
          : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        resetPasswordInfo: [],
        loading: false,
        success: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Password(formData) {
        try {
          this.REQUEST();

          const { data } = await api.put(
            `/auth/resetPassword/${formData.resetToken}`,
            formData
          );

          this.SUCCESS(data);
          return Promise.resolve(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

export const ForgotPassword = {
  state: {
    resetPasswordToken: "",
    forgotPasswordInfo: [],
    loading: false,
    success: null,
    error: null,
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        forgotPasswordInfo: payload?.data,
        resetPasswordToken: payload?.data[0].resetPasswordToken,
        success: payload?.status.message.includes("Successfully")
          ? payload?.status.message
          : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        resetPasswordToken: "",
        forgotPasswordInfo: [],
        loading: false,
        success: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Password(formData) {
        try {
          this.REQUEST();

          const { data } = await api.post(`/auth/forgotpassword`, formData);

          this.SUCCESS(data);
          return Promise.resolve(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

export const UpdatePassword = {
  state: {
    updatePasswordInfo: [],
    loading: false,
    success: null,
    error: null,
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        updatePasswordInfo: payload?.data,
        success: payload?.status.message.includes("Successfully")
          ? payload?.status.message
          : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        updatePasswordInfo: [],
        loading: false,
        success: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Password(formData) {
        try {
          this.REQUEST();

          const { data } = await api.put(
            `/auth/updatePassword/${formData.userId}`,
            formData
          );

          this.SUCCESS(data);
          return Promise.resolve(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

export const AuthLogout = {
  state: {},
  reducers: {
    USER_LOGOUT: (state, payload) => {
      return {};
    },
  },
  effects: (dispatch) => {
    const { AuthLogout } = dispatch;
    return {
      async logout() {
        document.location.href = "/login";
        localStorage.clear();
        AuthLogout.USER_LOGOUT();
      },
    };
  },
};
