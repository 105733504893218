import React from "react";
import { RocketIcon } from "@radix-ui/react-icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useRegisterSW } from "virtual:pwa-register/react";
import { pwaInfo } from "virtual:pwa-info";

// console.log(pwaInfo);

function ReloadPrompt() {
  const reloadSW = true;

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`);
      if (reloadSW) {
        r &&
          setInterval(() => {
            console.log("Checking for Service Worker Update");
            r.update();
          }, 20000 /* 20s for testing purposes */);
      } else {
        console.log("Service Worker Success: " + r);
      }
    },
    onRegisterError(error) {
      console.log("Service Worker Fail:", error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };
  return (
    <div className="relative">
      {(offlineReady || needRefresh) && (
        <div className="fixed z-[30] bottom-4 right-10 ">
          <Alert className="shadow-sm shadow-secondary">
            <RocketIcon className="h-4 w-4" />

            <div className="flex items-center">
              {offlineReady ? (
                <AlertTitle className="text-[16px]">
                  App ready to work offline
                </AlertTitle>
              ) : (
                <AlertTitle className="text-[16px]">
                  New update available, click on reload button to update.
                </AlertTitle>
              )}
            </div>

            <AlertDescription className="flex items-center flex-wrap gap-3">
              <Button
                size="sm"
                className="text-sm h-8  justify-center align-middle items-center lg:flex "
                type="button"
                variant="outline"
                onClick={() => close()}
              >
                Close
              </Button>
              {needRefresh && (
                <Button
                  size="sm"
                  className="text-sm h-8 justify-center align-middle items-center lg:flex "
                  type="button"
                  variant="default"
                  onClick={() => updateServiceWorker(true)}
                >
                  Reload
                </Button>
              )}
            </AlertDescription>
          </Alert>
        </div>
      )}
    </div>
  );
}

export default ReloadPrompt;
