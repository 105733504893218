import React, { Suspense, lazy } from "react";
import { ThemedSuspense } from "@/components/shared/Loading";
// import { DataTableDemo } from "screens/DataTableDemo";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<ThemedSuspense />}>
      <Component {...props} />
    </Suspense>
  );
};

const Dashboard = Loadable(lazy(() => import("screens/Dashboard/Dashboard")));
const Reversals = Loadable(lazy(() => import("screens/Reversal/index.jsx")));
const Wallet = Loadable(lazy(() => import("screens/Wallet/index.jsx")));
const DataTableDemo = Loadable(lazy(() => import("screens/DataTableDemo")));
const Customer = Loadable(lazy(() => import("screens/Customer/index.jsx")));
const ViewDetails = Loadable(lazy(() => import("screens/Wallet/ViewDetails")));
const DetailsPage = Loadable(lazy(() => import("screens/DetailsPage")));

export const Screens = [
  {
    path: "/dashboard",
    element: Dashboard,
  },
  {
    element: Wallet,
    path: "/walletRequests",
  },
  {
    element: Reversals,
    path: "/reversal",
  },
  {
    element: Customer,
    path: "/customer",
  },
  {
    element: ViewDetails,
    path: "/moreDetails",
  },
  {
    element: ViewDetails,
    path: "/detailView",
  },
  {
    element: DataTableDemo,
    path: "/table",
  },
  {
    element: DetailsPage,
    path: "/table/details",
  },
];
