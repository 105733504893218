import api from "@/lib/api";

// fetch failed
export const getFailedDisbursements = {
  state: {
    disbursements: [],
    totalCount: 0,
    error: undefined,
    loading: false,
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        ...state,
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursements: payload?.data,
        totalCount: payload?.paging.count
          ? payload?.paging.count
          : payload?.data.length,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursements: [],
        error: payload
          ? payload.response.data?.status.message
          : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async List(params) {
        try {
          this.REQUEST();

          let url = `/failedDisbursements/list?page=${params.page}&pageSize=${params.pageSize}`;

          if (params.isRejected !== undefined)
            url += `&isRejected=${params.isRejected}`;
          if (params.isApproved !== undefined)
            url += `&isApproved=${params.isApproved}`;
          if (params.isReadyForApproval !== undefined)
            url += `&isReadyForApproval=${params.isReadyForApproval}`;
          if (params.isProcessed !== undefined)
            url += `&isProcessed=${params.isProcessed}`;

          const { data } = await api.get(url);

          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

//faild Transactions
export const getFailedTransactions = {
  state: {
    disbursements: [],
    totalCount: 0,
    error: undefined,
    loading: false,
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        ...state,
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursements: payload?.data,
        totalCount: payload?.paging.count
          ? payload?.paging.count
          : payload?.data.length,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursements: [],
        error: payload
          ? payload.response.data?.status.message
          : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async List(params) {
        try {
          this.REQUEST();

          let url = `/failedDisbursements/failedTransactions?page=${params.page}&pageSize=${params.pageSize}`;

          if (params.isRejected !== undefined)
            url += `&isRejected=${params.isRejected}`;
          if (params.isApproved !== undefined)
            url += `&isApproved=${params.isApproved}`;

          const { data } = await api.get(url);

          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

// notifyAdmin
export const notifyAdminFailedRequest = {
  state: {
    disbursements: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursements: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursements: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        disbursements: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData, params) {
        try {
          this.REQUEST();
          const { data } = await api.put(
            `/failedDisbursements/notifyAdmin`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

// approve
export const approveFailedRequest = {
  state: {
    disbursements: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursements: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursements: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        disbursements: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData, params) {
        try {
          this.REQUEST();
          const { data } = await api.put(
            `/failedDisbursements/approve`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

// reject
export const rejectFailedRequest = {
  state: {
    disbursements: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursements: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursements: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        disbursements: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData, params) {
        try {
          this.REQUEST();
          const { data } = await api.put(
            `/failedDisbursements/reject`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};
//editFailedDisbursement

//create Failed Disbursements
export const addFailedDisbursement = {
  state: {
    disbursement: {},
  },

  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursement: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursement: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        disbursement: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Add(formData) {
        try {
          this.REQUEST();

          const { data } = await api.post(
            `/failedDisbursements/create`,
            formData
          );

          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

export const updateFailedDisbursement = {
  state: {
    disbursement: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursement: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursement: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        disbursement: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData) {
        try {
          this.REQUEST();
          const { data } = await api.put(
            `/failedDisbursements/${formData.failedDisbursementId}/edit`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

//delete Failed Disbursements
export const deleteFailedDisbursement = {
  state: {
    disbursement: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        disbursement: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        disbursement: null,
        error: payload ? payload.response.data.message : payload?.message,
      };
    },
    RESET: () => {
      return {
        disbursement: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Remove(formData) {
        try {
          this.REQUEST();
          const { data } = await api.delete(
            `/failedDisbursements/${formData.failedDisbursementId}`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};
