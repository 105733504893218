import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { ContextProvider } from "@/context/ContextProvider.jsx";
import { Provider } from "react-redux";
import store from "lib/store";
import "./globals.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "react-phone-number-input/style.css";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ContextProvider defaultTheme="light" storageKey="vite-ui-theme">
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ContextProvider>
    </Provider>
  </React.StrictMode>
);
