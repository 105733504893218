import axios from "axios";
import store from "./store";
import { getStorageData } from "lib/utils";

// Create an axios instance with baseURL and default headers
const api = axios.create({
  baseURL: process.env.URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add authorization token header to requests
api.interceptors.request.use(
  (config) => {
    // Get the token from localStorage and add it to the header
    config.headers = {
      "x-auth-token": `${getStorageData("token")}`,
      "x-server-key": process.env.KEY,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration and refresh token
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    if (
      (error.response.status === 401 &&
        error.response.data.status.message ===
          "TokenExpiredError: jwt expired") ||
      error.message === "Request failed with status code 401"
    ) {
      // Dispatch logout action if token expired
      return store.dispatch.AuthLogout.logout();
    }

    return Promise.reject(error);
  }
);

export default api;
