import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const Authentication = () => {
  const location = useLocation();

  const { loading, userInfo, successOTP } = useSelector(
    (state) => state.AuthOTP
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  return successOTP ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export const PrivateRoute = ({ roles }) => {
  const location = useLocation();
  const { loading } = useSelector((state) => state.AuthOTP);

  const RequiredRole = roles ? true : false;

  if (loading) {
    return <p>Loading...</p>;
  }

  return RequiredRole ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};
