import api from "@/lib/api";

// fetch Customer
export const getCustomer = {
  state: {
    customer: [],
    totalCount: 0,
    error: undefined,
    loading: false,
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        ...state,
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        customer: payload?.data,
        totalCount: payload?.paging.count
          ? payload?.paging.count
          : payload?.data.length,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        customer: [],
        error: payload
          ? payload.response.data?.status.message
          : payload?.message,
      };
    },
  },
  effects: (dispatch) => {
    return {
      async List(params) {
        try {
          this.REQUEST();

          let url = `/walletRequests/list?page=${params.page}&pageSize=${params.pageSize}`;
          if (params.isReadyForApproval !== undefined)
            url += `&isReadyForApproval=${params.isReadyForApproval}`;
          if (params.isRejected !== undefined)
            url += `&isRejected=${params.isRejected}`;
          if (params.isProcessed !== undefined)
            url += `&isProcessed=${params.isProcessed}`;

          const { data } = await api.get(url);
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

//create customer requests
export const addCustomer = {
  state: {
    customer: {},
  },

  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        customer: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        customer: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        customer: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Add(formData) {
        try {
          this.REQUEST();

          const { data } = await api.post(`/walletRequests/create`, formData);

          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

// edit customer request
export const updateCustomer = {
  state: {
    customer: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        customer: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        customer: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        customer: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData, params) {
        try {
          this.REQUEST();
          const { data } = await api.put(
            `/walletRequests/${formData.walletRequestId}/edit`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

// notifyAdmin
export const notifyAdminCustomerRequest = {
  state: {
    customer: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        customer: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        customer: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        customer: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData, params) {
        try {
          this.REQUEST();
          const { data } = await api.put(
            `/walletRequests/notifyAdmin`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};

// approve
export const approveCustomerRequest = {
  state: {
    customer: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        customer: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        customer: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        customer: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Update(formData, params) {
        try {
          this.REQUEST();
          const { data } = await api.put(`/walletRequests/approve`, formData);
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};



//delete customer Requisition
export const deleteCustomer = {
  state: {
    customer: {},
  },
  reducers: {
    REQUEST: (state, payload) => {
      return {
        loading: true,
      };
    },
    SUCCESS: (state, payload) => {
      return {
        loading: false,
        customer: payload,
        success: payload.status.message ? payload.status.message : null,
      };
    },
    FAIL: (state, payload) => {
      return {
        loading: false,
        customer: null,
        error: payload
          ? payload.response.data.status.message
          : payload?.message,
      };
    },
    RESET: () => {
      return {
        customer: [],
      };
    },
  },
  effects: (dispatch) => {
    return {
      async Remove(formData) {
        try {
          this.REQUEST();
          const { data } = await api.delete(
            `/walletRequests/${formData.walletRequestId}`,
            formData
          );
          this.SUCCESS(data);
        } catch (error) {
          this.FAIL(error);
        }
      },
    };
  },
};
