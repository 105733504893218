import { useState, useEffect } from "react";
import Layout from "@/container/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "@/screens/Auth/LoginScreen";
import ForgotPassword from "screens/Auth/ForgotPassword";
import { Screens } from "config/routes";
import { useSelector } from "react-redux";
import NotFound from "container/NotFound";
import Unauthorized from "container/Unauthorized";
import { Authentication, PrivateRoute } from "container/PrivateRoute";
import OTPScreen from "screens/Auth/OTPScreen";
import { ToastNotification } from "components/shared/Alert";
import { GlobalDebug } from "lib/utils";

function App() {
  const AuthOTP = useSelector((state) => state.AuthOTP);
  const { userInfo } = AuthOTP;

  /* The `useEffect` hook in the provided code snippet is used to conditionally call the `GlobalDebug`
function based on the environment variable `process.env.ENV` to Remove console logs in production env.. */
  useEffect(() => {
    process.env.ENV === "production" && GlobalDebug(false);
  }, []);

  return (
    <BrowserRouter>
      <ToastNotification />
      <Routes>
        {["/", "login"].map((path, index) => (
          <Route key={index} path={path} element={<LoginScreen />} />
        ))}
        <Route path="/OTPVerification" element={<OTPScreen />} />
        <Route path="/forgotpass" element={<ForgotPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* Protected routes */}

        <Route element={<Authentication />}>
          <Route element={<Layout />}>
            {Screens.map((item, i) => (
              <Route key={i} path={item.path} element={<item.element />} />
            ))}
          </Route>
        </Route>

        {/* Catch all */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
