import {
  AuthLogin,
  AuthLogout,
  AuthOTP,
  AuthResetPassword,
  UpdatePassword,
  ForgotPassword,
} from "./Auth";
import {
  getWalletRequisition,
  addWalletRequisition,
  updateWalletRequisition,
  notifyAdminRequest,
  approveRequest,
  rejectRequest,
  deleteWalletRequisition,
  getWalletRequisitionStats,
} from "./WalletRequests";

import {
  getFailedDisbursements,
  notifyAdminFailedRequest,
  approveFailedRequest,
  rejectFailedRequest,
  addFailedDisbursement,
  updateFailedDisbursement,
  deleteFailedDisbursement,
  getFailedTransactions,
} from "./FailedReversals";

import {
  addCustomer,
  notifyAdminCustomerRequest,
  approveCustomerRequest,
  getCustomer,
  deleteCustomer,
  updateCustomer,
} from "./Customer";

export const models = {
  //auth
  AuthLogin,
  AuthLogout,
  AuthOTP,
  AuthResetPassword,
  UpdatePassword,
  ForgotPassword,

  //wallet
  getWalletRequisition,
  getWalletRequisitionStats,
  addWalletRequisition,
  updateWalletRequisition,
  notifyAdminRequest,
  approveRequest,
  rejectRequest,
  deleteWalletRequisition,

  //reversals
  getFailedDisbursements,
  notifyAdminFailedRequest,
  approveFailedRequest,
  rejectFailedRequest,
  addFailedDisbursement,
  updateFailedDisbursement,
  deleteFailedDisbursement,
  getFailedTransactions,

  //customer
  addCustomer,
  notifyAdminCustomerRequest,
  approveCustomerRequest,
  getCustomer,
  deleteCustomer,
  updateCustomer,
};
