import React, { useEffect, useState, useRef } from "react";
import { Form } from "components/ui/form";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {  FormOTP } from "@/components/shared/Input";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "context/ContextProvider";
import { getStorageData } from "lib/utils";
import { Message } from "components/shared/Message";
import { Loader2 } from "lucide-react";
import { LoginIcon } from "assets/icons";

const OTPScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useForm();
  const { currentColor, setCurrentColor } = useStateContext();

  const inputRefs = useRef(null);
  const [value, setValue] = useState("");
  const [maskedOTP, setMaskedOTP] = useState("");

  const AuthOTP = useSelector((state) => state.AuthOTP);
  const { loading, error, userInfo, successOTP } = AuthOTP;

  useEffect(() => {
    if (successOTP) {
      navigate("/dashboard");
    }
  }, [navigate, successOTP, userInfo]);

  const onSubmit = async (values) => {
    const formData = {
      userId: getStorageData("userId"),
      OTP: values.otp,
    };

    // console.log(formData);
    await dispatch.AuthOTP.VerifyOTP(formData);
  };
  return (
    <div className="w-full lg:grid lg:min-h-screen lg:grid-cols-2 xl:min-h-screen min-h-screen">
      <div className="hidden h-full bg-gray-100 lg:flex items-center justify-center flex-1 text-black min-h-screen">
        <div className="max-w-md text-center pt-48 pb-0  min-h-screen">
          <LoginIcon />
        </div>
      </div>
      <div className="w-full  md:min-h-[689px] flex items-center justify-center sm:min-h-screen sm:justify-center sm:items-center min-h-screen">
        <div className="lg:max-w-md md:max-w-md w-full p-6 rounded-md shadow-lg lg:rounded-none lg:shadow-none" style={{ width: '97%' }}>
          <div className="grid gap-2 text-center">
            {/* //header */} 
            <CardHeader className="space-y-2">
              <CardTitle className="text-2xl text-center">
                One-Time Password
              </CardTitle>
              <CardDescription className="text-center text-sm text-muted-foreground">
                Please input the one-time password sent to your Phone number or
                email
                {error ? (
                  <Message
                    className={`text-center text-lg font-semibold capitalize`}
                  >
                    Error: {error}
                  </Message>
                ) : null}
                <span className="flex items-center justify-center text-sm">
                  {/* {loading && <Loading />} */}
                </span>
              </CardDescription>
            </CardHeader>
          </div>

          {/* //form */}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="">
              <CardContent className="space-y-3 items-center">
                <div className=" px-10 flex justify-center items-center">
                  <FormOTP
                    name="otp"
                    maxLength={6}
                    required={true}
                    control={form.control}
                  />
                </div>
              </CardContent>
              <CardFooter className="flex justify-center items-center">
              <Button
                    disabled={loading}
                    className=" w-[50%] text-sm h-8 lg:flex justify-center align-middle items-center"
                    variant="default"
                    size="sm"
                    type="submit"
                  >
                    {loading ? (
                      <span className=" flex items-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Please wait
                      </span>
                    ) : (
                      `Verify`
                    )}
                  </Button>
              
              </CardFooter>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OTPScreen;
