import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "libphonenumber-js";

const phoneRegex = RegExp(/^(2547|2541)[0-9]{8}$/);
const phoneRegexB = RegExp(/^(07|01)[0-9]{8}$/);
const name = RegExp(/^[a-zA-Z '.-]*$/);
const KRA = RegExp(/^[A]{1}[0-9]{9}[a-zA-Z]{1}$/);
const number = RegExp(/^[0-9]*$/);
const IDNumber = RegExp(/^[0-9]{6,9}$/);
const passport = RegExp(/^[A-Z]{2}[0-9]{7}$/);
let plateNumber = /[A-Z]{3}\d{3}\S$/g;

const validateKenyanPhoneNumber = (value) => {
  if (!value) return false;
  const patterns = [
    /^2547\d{8}$/, // 2547XXXXXXXX
    /^2541\d{8}$/, // 2541XXXXXXXX
    /^25407\d{8}$/, // 25407XXXXXXXX
    /^25401\d{8}$/, // 25401XXXXXXXX
    /^07\d{8}$/, // 07XXXXXXXX
    /^01\d{8}$/, // 01XXXXXXXX
  ];
  return patterns.some((pattern) => pattern.test(value));
};

export const LoginSchema = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .min(4, "Password must be at least 4 characters.")
      .max(40, "Password must not exceed 40 characters")
      .required("Password Required!"),
    // types: Yup.string().required("Types is required"),
  })
);

export const forgotPasswordSchema = yupResolver(
  Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  })
);

export const addWalletSchema = yupResolver(
  Yup.object().shape({
    description: Yup.string().required("description is required"),
    amount: Yup.number().required("amount is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .test("isValid", "Phone number is not valid", (value) =>
        isValidPhoneNumber(`${value}`, "KE")
      ),
  })
);

export const editWalletSchema = yupResolver(
  Yup.object().shape({
    description: Yup.string().required("description is required"),
    amount: Yup.number().required("amount is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .test("isValid", "Phone number is not valid", (value) =>
        isValidPhoneNumber(`${value}`, "KE")
      ),
  })
);
export const addDisbursementSchema = yupResolver(
  Yup.object().shape({
    narrative: Yup.string().required("Narrative is required"),
  })
);
export const editDisbursementSchema = yupResolver(
  Yup.object().shape({
    narrative: Yup.string().required("Narrative is required"),
  })
);
