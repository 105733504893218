import { Form } from "components/ui/form";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FormInput } from "@/components/shared/Input";
import { forgotPasswordSchema } from "lib/validationSchema";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FaUserShield } from "react-icons/fa";

const ForgotPass = () => {
  const navigate = useNavigate();

  const form = useForm({
    resolver: forgotPasswordSchema,
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (values) => {
    const formData = {
      email: values.email,
    };

    console.log(formData);
  };

  return (
    <div className="themeBackground flex flex-row min-h-screen justify-center items-center bg-slate-100">
      <Card className="w-full max-w-xl">
        <CardHeader className="space-y-0">
          <CardTitle className="text-2xl text-center">
            Forgot Password
          </CardTitle>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="">
            <CardContent className="space-y-3">
              <FormInput
                name="email"
                label="Email"
                type="email"
                required={true}
                control={form.control}
              />
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
                className="rounded bg-black text-white dark:bg-white dark:text-black "
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="default"
                className="rounded text-white dark:bg-white dark:text-black "
              >
                Recover Password
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPass;
