import { BsHouseFill } from "react-icons/bs";
import { FaFileSignature, FaUser, FaWallet, FaTable } from "react-icons/fa";
import { MdOutlineSmsFailed } from "react-icons/md";

export const Navbar = [
  {
    title: "Home",
    path: "/home",
    icon: "",
  },
];

export const MenusList = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: BsHouseFill,
  },

  {
    title: "Wallet Requests",
    path: "/walletRequests",
    icon: FaWallet,
  },

  {
    title: "Reversals",
    path: "/reversal",
    icon: MdOutlineSmsFailed,
  },

  {
    title: "Customers",
    path: "/customer",
    icon: FaUser,
  },

  // {
  //   title: "Table",
  //   path: "/table",
  //   icon: FaTable,
  // },
];
