import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../context/ContextProvider";

const Unauthorized = () => {
  const { currentColor, Roles, setRoles } = useStateContext();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <section
      className={`themeBackground themeText nav-item fixed bottom-0 left-0 right-0 top-0  h-screen w-screen bg-white/30 backdrop-blur-3xl`}
    >
      <div className="my-6 flex flex-col items-center">
        <i className={`fas fa-ban mt-8 h-14 w-14 text-6xl  text-red-500`} />

        <h1 className="my-3 text-4xl font-semibold text-gray-700 dark:text-gray-200">
          Unauthorized
        </h1>

        <div className="flex text-lg text-gray-700 dark:text-gray-300">
          <p>You do not have access to the requested page. </p>{" "}
          <>
            <div className="flex-grow">
              <button type="button" onClick={goBack}>
                <span className={`hover:underline text-[${currentColor}]`}>
                  Go Back
                </span>
              </button>
            </div>
          </>
        </div>
      </div>
    </section>
  );
};

export default Unauthorized;
